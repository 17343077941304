import React, { Component } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/bundle';
import Style from './SwiperPagination.module.less';
// import classname from "classnames";
import FJUtil from '../../../src/util/FJUtil';

export default class FJTemplateListSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceBetween: 20,
      isBegin: true,
      isEnd: false,
    };
    this.swiperRef = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    FJUtil.addEvent(window, 'resize', this.updateUI);
    this.updateUI();
  }

  componentWillUnmount() {
    FJUtil.removeEvent(window, 'resize', this.updateUI);
    this.timer && clearTimeout(this.timer);
  }

  updateUI = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      if (this.swiperRef.current) {
        const swiper = this.swiperRef.current.swiper;
        swiper.update(); // 调用Swiper实例的update方法
        clearTimeout(this.timer);
        this.timer = null;
      }
    }, 500);
  };

  render() {
    const { clientData = [], drawSwiperSlideItem, delay = 0 } = this.props;
    // thumbsContainerClass
    return (
      <Swiper
        ref={this.swiperRef}
        className={Style.swiper}
        spaceBetween={this.state.spaceBetween}
        slidesPerView='auto'
        modules={[Pagination, Autoplay]}
        pagination={{
          bulletClass: Style.swiperPaginationBulletFJ,
          bulletActiveClass: Style.swiperPaginationBulletActiveFJ,
          clickable: true,
          horizontalClass: Style.horizontalBox,
        }}
        onTransitionEnd={data => {
          this.setState({
            isBegin: data.isBeginning,
            isEnd: data.isEnd,
          });
        }}
        speed={2000}
        loop={true}
        autoplay={{
          enabled: true,
          delay,
        }}
      >
        {clientData.map((item, index) => {
          return (
            <SwiperSlide key={index} className={Style.swiperSlide}>
              {drawSwiperSlideItem(item)}
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }
}
