import React, { useRef, useEffect, useState } from 'react';
import Style from './FJTemplate.module.less';
import classNames from 'classnames';
import FJConfig from '../../../src/config/FJConfig';
import FJUtil from '../../../src/util/FJUtil';

export default function SwiperSlot(props) {
  const {
    slotProps = {},
    size = '16:9',
    fullPreview = (video, url) => {},
    customizeClick = () => {},
    textLeft,
    textRight,
    category = '',
    titleStyle = {},
  } = props;

  /**
   * 图片加载错误
   */
  let imgStyle = {};
  if (size === '16:9') {
    imgStyle.width = 'auto';
  } else if (size === '1:1') {
    imgStyle.width = 'auto';
    imgStyle.height = '402px';
  }

  let defaultImg = '';
  let imgURL = `https://resource.flexclip.com/templates/cover/w400/${slotProps.name}.webp?v=${FJConfig.buildVersion}`;
  let videoURL = `https://resource.flexclip.com/templates/video/720p/${slotProps.name}.mp4?v=${FJConfig.buildVersion}`;
  let fullPreviewVideoURL = `https://resource.flexclip.com/templates/video/720p/${slotProps.name}.mp4?v=${FJConfig.buildVersion}`;

  let lazyLoadThrottleTimeout;

  function imgLazyLoading() {
    lazyLoadThrottleTimeout = setInterval(function () {
      // var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      const { left, right, width } = imageRef.current.getBoundingClientRect();

      const visible = right >= 0 && left <= document.documentElement.clientWidth;
      if (visible) {
        imageRef.current.src = imageRef.current.getAttribute('data-src');
        imageRef.current.removeAttribute('data-src');
        imageRef.current.style.opacity = 1;
        clearInterval(lazyLoadThrottleTimeout);
        lazyLoadThrottleTimeout = null;
      }
    }, 500);
  }

  function imageError() {
    if (slotProps.name === '') return;
    imgURL = '';
  }

  let box = useRef(null);
  let imageRef = useRef(null);
  let [displayVideo, setDisplayVideo] = useState(false);
  let [hiddenCover, setHiddenCover] = useState(false);

  function mouseOverDisplayVideo() {
    setDisplayVideo(true);
  }

  function mouseOutDisplayVideo() {
    setDisplayVideo(false);
    setHiddenCover(false);
  }

  useEffect(() => {
    imgLazyLoading();
    FJUtil.addEvent(box.current, 'mouseenter', mouseOverDisplayVideo);
    FJUtil.addEvent(box.current, 'mouseleave', mouseOutDisplayVideo);

    return () => {
      FJUtil.removeEvent(box.current, 'mouseenter', mouseOverDisplayVideo);
      FJUtil.removeEvent(box.current, 'mouseleave', mouseOutDisplayVideo);
      clearInterval(lazyLoadThrottleTimeout);
      lazyLoadThrottleTimeout = null;
    };
  }, []);

  function videoPlay() {
    setHiddenCover(true);
  }

  const itemVideoClick = () => {
    const childAllowClick = sessionStorage.getItem('childAllowClick');
    if (childAllowClick !== 'true') {
      event.preventDefault(); // 阻止默认的 click 事件
      event.stopPropagation(); // 阻止事件传播
    } else {
      FJUtil.ealog('home template click', {
        'template name': slotProps.span,
        category: category,
        preview: 'cover',
        customize: 'hover button',
      });
      fullPreview(slotProps, fullPreviewVideoURL);
    }
  };

  function __drawVideo() {
    return (
      <div className={Style.video}>
        <video
          onCanPlay={videoPlay}
          onClick={itemVideoClick}
          controls={false}
          preload='load'
          loop
          autoPlay
          muted={true}
          src={videoURL}
          poster={imgURL}
          width={410}
          height={252}
        />
      </div>
    );
  }

  return (
    <div className={classNames('lang-special', Style.itemBox)}>
      <div className={Style.slot_box} ref={box}>
        <div className={classNames(Style.image, hiddenCover ? Style.imageHidden : '')}>
          <img
            ref={imageRef}
            width={500}
            height={280}
            data-name={slotProps.name || ''}
            draggable='false'
            data-src={imgURL}
            src={defaultImg}
            onError={imageError}
            onClick={itemVideoClick}
            alt={slotProps['span'] || slotProps['name']}
            loading='lazy'
          />
        </div>
        {displayVideo && slotProps.name ? __drawVideo() : ''}
        <div className={classNames(Style.mask)}>
          <div className={Style.maskBtnBox}>
            <div
              className={classNames(Style.previewBtn)}
              onClick={e => {
                e.preventDefault();
                FJUtil.ealog('home template click', {
                  'template name': slotProps.span,
                  category: category,
                  preview: 'button',
                });
                fullPreview(slotProps, fullPreviewVideoURL);
              }}
            >
              {textLeft}
            </div>
            <div
              className={classNames(Style.customizeBtn)}
              onClick={e => {
                e.preventDefault();
                FJUtil.ealog('home template edit click', {
                  'template name': slotProps.span,
                });
                customizeClick(slotProps);
              }}
            >
              {textRight}
            </div>
          </div>
        </div>
      </div>
      {/*<p className={ Style.templateTitle } style={ titleStyle }*/}
      {/*   dangerouslySetInnerHTML={ { __html: slotProps['name_translate'] || 'name_translate' } }/>*/}
    </div>
  );
}
