import { Component } from 'react';
import classname from 'classnames';
import Style from './FJButtonBrick.module.less';
class FJButtonBrick extends Component {
  _click = e => {
    let { URL, onClick, item } = this.props;
    if (onClick) {
      e.preventDefault();
      onClick(item);
    } else {
      window.location.href = URL;
    }
  };

  render() {
    let { text, URL, className, type = 'default', children } = this.props;
    return (
      <a
        href={URL}
        onClick={e => {
          this._click(e);
        }}
        className={classname(type === 'default' ? Style.default : '', className)}
      >
        {children ? children : text}
      </a>
    );
  }
}
export default FJButtonBrick;
