import React, { Component } from 'react';
import FJUtil from '../../../util/FJUtil';
import classes from 'classnames';

export default class FJKeepBgHeight extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.beforeTargetDomList = [];
  }

  __drawContainer() {
    const { children, keepBgHeightTarget = 'keepBgHeightTarget', className = '', style = {} } = this.props;

    return React.createElement(
      'div',
      {
        className: classes(keepBgHeightTarget, className),
        style,
        ref: this.containerRef,
      },
      children,
    );
  }

  getStyle() {
    const { bgImg = '', heightPercent = 90, keepBgHeightTarget = 'keepBgHeightTarget' } = this.props;

    let bgHeight = '';

    for (let i = 0; i < this.containerRef.current.childNodes.length; i++) {
      const item = this.containerRef.current.childNodes[i];
      if (item.className && item.className.indexOf(keepBgHeightTarget) !== -1) {
        bgHeight =
          parseFloat(this.containerRef.current.paddingTop || 0) +
          item.offsetTop +
          (item.offsetHeight * heightPercent) / 100;
        break;
      }
    }

    return `
      .${keepBgHeightTarget}::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: ${bgHeight}px;
        background-image: url(${bgImg || ''});
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
       }`;
  }

  updateHeight() {
    const { keepBgHeightTarget = 'keepBgHeightTarget' } = this.props;

    const newStyle = this.getStyle();

    const styleElement = document.createElement('style');
    styleElement.id = `${keepBgHeightTarget}StyleTag`;
    styleElement.textContent = newStyle;
    document.head.appendChild(styleElement);
  }

  resize = () => {
    const { keepBgHeightTarget = 'keepBgHeightTarget' } = this.props;
    const newStyle = this.getStyle();

    const styleTag = document.getElementById(`${keepBgHeightTarget}StyleTag`);
    styleTag.textContent = newStyle;
  };

  componentDidMount() {
    this.updateHeight();
    FJUtil.addEvent(window, 'resize', this.resize);
  }

  componentWillUnmount() {
    FJUtil.removeEvent(window, 'resize', this.resize);
  }

  render() {
    return this.__drawContainer();
  }
}
