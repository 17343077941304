import Style from './FJTabs.module.less';
import {useEffect, useState} from "react";
import classname from "classnames";

/**
 * 使用该组件时，options（渲染数组）必传，tabClick（子项点击事件）必传
 * @param props: options（渲染数组）、displayKey（dom中的文字用哪个字段渲染）、tabClick（子项点击事件）
 * 如果传入的options是字符串数组，则displayKey不用传，传进来也没意义
 */

export default function FJTabs(props) {
  const {options, displayKey = '', tabClick, style = {}, itemClass = '', activeClass = '', boxClass=''} = props;
  const [activeIndex, setActiveIndex] = useState(0)

  function classifyItemClick(index, obj) {
    if (activeIndex === index) {
      return;
    }
    tabClick && tabClick(index, obj);
    clickAndMoveDom(index);
  }

  function clickAndMoveDom(index) {
    setActiveIndex(index);

    // 做滚动效果
    const father = document.getElementById('tabs-box1325555');
    // scrollWidth 可视、隐藏相加宽度， offsetWidth 本身宽度，包括隐藏， clientWidth 在窗口中的可视宽度
    const fatherWidth = father ? father.offsetWidth : 0;
    const item = document.getElementById('tabs-item' + index);
    // const itemStyle = window.getComputedStyle(item, null);
    const standardOffsetLeft = item.offsetLeft + item.offsetWidth;
    const centerPosition = ((fatherWidth + item.offsetWidth) / 2).toFixed(0);
    father.scrollTo({
      left: standardOffsetLeft - parseInt(centerPosition),
      behavior: 'smooth',
    });

    // const animation = document.getElementById('tabs-box-animation1325555')
    // animation.style.width = item.clientWidth + 2 + 'px';
    // animation.style.left = item.offsetLeft + 'px';
  }


  function __drawClassifyItem() {
    const list = []
    return (options || list).map((item, index) => {
      return (
        <li
          key={index}
          id={`tabs-item${index}`}
          className={`${Style.tabsItem} ${itemClass ? itemClass : ''} ${index === activeIndex ? activeClass ? activeClass : Style.tabsItemActive : ''}`}
          onClick={() => {
            classifyItemClick(index, item)
          }}
        >
          {typeof item === 'object' ? item[displayKey] : item}
        </li>
      )
    })
  }

  useEffect(() => {
    clickAndMoveDom(0);
  }, [])

  return (
    <ul id="tabs-box1325555" className={classname(Style.tabsBox, boxClass)} style={style}>
      {__drawClassifyItem()}
      <li id="tabs-box-animation1325555" className={Style.animation}/>
    </ul>
  )
}